import React from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import "./PageLoadSpinner.css";

export default function pageLoadSpinner(isLoading) {
  return (
    <div className="loader-container">
      <div className="page-loader">
        <PropagateLoader
          size={25}
          color={"#68aee8"}
          loading={isLoading}
        />
      </div>
    </div>
  );
}