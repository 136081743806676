export default {
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://2bnphq3ysc.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_HXdCU5KhC",
      APP_CLIENT_ID: "scfqb0u2vqimo2jc68dlkmmli",
      IDENTITY_POOL_ID: "us-east-1:97fc7d75-bc51-441d-80cb-0edaa1b240ba"
    }
  };
  