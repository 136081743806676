import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { onError } from "../libs/errorLib";
import config from "../config";
import { Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { FormGroup, FormLabel, FormControl} from "react-bootstrap";
import "./Settings.css";
import pageLoadSpinner from "../components/PageLoadSpinner"


export default function Settings() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const { isAuthenticated } = useAppContext();
  const [leagueBotUserInfo, setLeagueBotUserInfo] = useState("");

  const Paddle = window.Paddle;
  const openCheckout  = () => {
      var sourceOfSale = ''
      if ('marketingSource' in leagueBotUserInfo && leagueBotUserInfo.marketingSource){
        sourceOfSale = leagueBotUserInfo.marketingSource
      }
    
      else{
        sourceOfSale = 'N/A'
      }

      Paddle.Checkout.open({ 
        product: 629041,
        passthrough: "{" + "\"cognitoIdentityID\": " + "\"" + userInfo.id +"\"," +
                       "\"cognitoSub\": " + "\"" + userInfo.attributes.sub +"\"," +
                       "\"sourceOfSale\": " + "\"" + sourceOfSale +"\"," +
                       "\"cognitoEmail\": " + "\"" + userInfo.attributes.email +"\"" + "}"
    });
  }
  

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
  
      try {
        setIsLoading(true);
        const info = await loadUserInfo();
        setUserInfo(info);

        var lbUserInfo = await loadLeagueBotUserInfo(info.id)
        
        if (lbUserInfo.activeSubscription === "missing user entry"){
          const poolInfo = await loadUserPoolInfo();
      
          // call this from inside addUser lambda after if exists check to save time
          const timeOfCreationResponse = await loadTimeOfCreation({userPoolId: poolInfo.pool.userPoolId, username: poolInfo.username})
    
          const addUserResponse = await addUser({cognitoEmail: poolInfo.attributes.email,
                                                 cognitoSub: poolInfo.attributes.sub,
                                                 timeOfCreation: timeOfCreationResponse.timeOfCreation
                                                  });
          // how to wait for user to be added before grabbing lbUserInfo again?
          const trash = addUserResponse

          lbUserInfo = await loadLeagueBotUserInfo(info.id)
        }

        setLeagueBotUserInfo(lbUserInfo)

      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated]);

  function loadUserInfo(){
    return Auth.currentUserInfo() //Auth.currentAuthenticatedUser()
  }
  function addUser(user) {
    return API.post("leagues", "/users", {body: user});
  }
  
  function loadUserPoolInfo(){
    return Auth.currentAuthenticatedUser()
  }

  function loadTimeOfCreation(params){
    return API.post("leagues", "/users/timeOfCreation", {body: params});
  }

  function loadLeagueBotUserInfo(cognitoID) {
    return API.get("leagues", `/users/${cognitoID}`);
  }

  function renderAccountStatus(){
    if (leagueBotUserInfo.activeSubscription === 0) {
      return (
      <div>
      <FormGroup controlId="user_active_sub">
            <div><FormLabel>Account Status</FormLabel></div>
            <FormControl
                as="input"
                type="text"
                value="Free Trial"
                disabled
              ></FormControl>
        </FormGroup>
        <FormGroup controlId="trial_expiration">
            <div><FormLabel>Trial Expiration</FormLabel></div>
            <FormControl
                as="input"
                type="text"
                value={leagueBotUserInfo.trialValidToDate}
                disabled
              ></FormControl>
        </FormGroup>
        </div>);
      }
      
      if (leagueBotUserInfo.activeSubscription === 1) {
        return(
          <FormGroup controlId="user_active_sub">
            <div><FormLabel>Account Status</FormLabel></div>
            <FormControl
                as="input"
                type="text"
                value="Active"
                disabled
              ></FormControl>
        </FormGroup>);
      }
  }

  return (
    <div className="Settings">
      {isLoading ? pageLoadSpinner(isLoading) : 
        <form>
            <FormGroup controlId="user_email">
                <div><FormLabel>Email</FormLabel></div>
                <FormControl
                    as="input"
                    type="text"
                    value={leagueBotUserInfo.cognitoEmail ? leagueBotUserInfo.cognitoEmail : ""}
                    disabled
                  ></FormControl>
            </FormGroup>
            {(leagueBotUserInfo.activeSubscription === 0 || leagueBotUserInfo.activeSubscription === 1) &&
              renderAccountStatus()
            }
            <FormGroup controlId="user_league_limit">
                <div><FormLabel>League Limit</FormLabel></div>
                <FormControl
                    as="input"
                    type="text"
                    value={leagueBotUserInfo.leagueLimit ? leagueBotUserInfo.leagueLimit : ""}
                    disabled
                  ></FormControl>
            </FormGroup>
        
          <Button block size="large" variant="primary" onClick={!isLoading && userInfo ? openCheckout : undefined}>{leagueBotUserInfo.activeSubscription === 1 ? "Increase League Limit" : "Purchase"}</Button>{' '}
          </form>
        }
    </div>
  );
}