import React, { useState, useEffect } from "react";
import { ListGroup, ListGroupItem, Button } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import "./Home.css";
import { API, Auth } from "aws-amplify";
import { LinkContainer} from "react-router-bootstrap";
import pageLoadSpinner from "../components/PageLoadSpinner";
import {Link} from "react-router-dom";

export default function Home() {
  const [leagues, setLeagues] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [numLeagues, setNumLeagues] = useState(1000);

  const [leagueBotUserInfo, setLeagueBotUserInfo] = useState("");
  const [userInfo, setUserInfo] = useState("");

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
  
      try {
        const leagues = await loadLeagues();
        setLeagues(leagues);

        if (leagues){
          setNumLeagues(leagues.length)
        }

        else{
          setNumLeagues(0)
        }

        setIsLoading(true);
        const info = await loadUserInfo();

        setUserInfo(info);

        var lbUserInfo = await loadLeagueBotUserInfo(info.id)
        
        if (lbUserInfo.activeSubscription === "missing user entry"){
          const poolInfo = await loadUserPoolInfo();
      
          // call this from inside addUser lambda after if exists check to save time
          const timeOfCreationResponse = await loadTimeOfCreation({userPoolId: poolInfo.pool.userPoolId, username: poolInfo.username})
    
          const addUserResponse = await addUser({cognitoEmail: poolInfo.attributes.email,
                                                 cognitoSub: poolInfo.attributes.sub,
                                                 timeOfCreation: timeOfCreationResponse.timeOfCreation
                                                  });
          // how to wait for user to be added before grabbing lbUserInfo again?
          const trash = addUserResponse

          lbUserInfo = await loadLeagueBotUserInfo(info.id)
        }

        setLeagueBotUserInfo(lbUserInfo)
        
        
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated]);
  
  function loadLeagues() {
    return API.get("leagues", "/leagues");
  }

  function loadUserInfo(){
    return Auth.currentUserInfo() //Auth.currentAuthenticatedUser()
  }
  function addUser(user) {
    return API.post("leagues", "/users", {body: user});
  }
  
  function loadUserPoolInfo(){
    return Auth.currentAuthenticatedUser()
  }

  function loadTimeOfCreation(params){
    return API.post("leagues", "/users/timeOfCreation", {body: params});
  }

  function loadLeagueBotUserInfo(cognitoID) {
    return API.get("leagues", `/users/${cognitoID}`);
  }

  function renderLeaguesList(leagues) {

    return [{}].concat(leagues).map((league, i) =>
        i !== 0 ? (
        <LinkContainer key={league.leagueUUID} to={`/leagues/${league.leagueUUID}`}>
            <ListGroupItem header={league.leagueName}>
            <b>{league.leagueName}</b>
            <br></br>
            {"Messaging Platform: " + (league.botPlatform === "groupme" ? "GroupMe" : league.botPlatform)}
            <br></br>
            {"League Platform: " + (league.leaguePlatform === "espn" ? "ESPN" : league.leaguePlatform)}
            </ListGroupItem>
        </LinkContainer>
        ) : (
        <LinkContainer disabled={leagues && leagueBotUserInfo && numLeagues >= leagueBotUserInfo.leagueLimit ? true : false} key="new" to="/leagues/new">
            <ListGroupItem>
            <div>
              {leagues && leagueBotUserInfo && numLeagues >= leagueBotUserInfo.leagueLimit ? (<h5><b>{"\uFF0B"}</b>{`Maximum number of leagues allowed (${leagueBotUserInfo.leagueLimit}). Please `} <Link to="/settings" style={{pointerEvents: "auto"}}>{"complete a purchase"}</Link> {"to increase limit."}</h5>) : (<h5><b>{"\uFF0B"}</b>{"Add a new league"}</h5>) }
            </div>
            </ListGroupItem>
        </LinkContainer>
        )
        );
    }

  function renderLander() {
      return (
        <div className="lander">
            <h1> LeagueBot</h1>
          <p>Kick the mid-season slump.</p>
            <p>Get your fantasy football league talking again.</p>
            <LinkContainer to="/signup">
              <Button className="signUpButton" size="lg">Sign me up!</Button>
            </LinkContainer>
            <p className="belowSignup">(One Week Free Trial. No credit card required.)</p>
        </div>
      );
    }

    function renderLeagues() {
      return (
        <div className="leagues">
         <h2>Your Leagues</h2>
         <br></br>
         {isLoading ? pageLoadSpinner(isLoading): 
          <ListGroup>
            {!isLoading && renderLeaguesList(leagues)}
          </ListGroup>
        }
        </div>
      );
    }

  return (
    <div className="Home">
      {isAuthenticated ? renderLeagues() : renderLander()}
    </div>
  );
}
