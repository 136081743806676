import React, { useRef, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, FormControl, FormLabel, FormCheck, Button, OverlayTrigger, Tooltip, Image, Accordion, Card} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import config from "../config";
import "./NewLeague.css";
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import { API } from "aws-amplify";
import leagueIdPic from '../images/espn_league_id.png'
import timezoneList from '../data/timezones.json'
import AccordionContext from 'react-bootstrap/AccordionContext';
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';
import classNames from 'classnames';

export default function NewLeague() {
  const history = useHistory();
  const [bot_platform, setbot_platform] = useState("groupme");
  const [league_platform, setleague_platform] = useState("espn");
  const [league_id, setleague_id] = useState("");
  const [league_name, setleague_name] = useState("");
  //const [bot_id, setbot_id] = useState("Enter GroupMe bot ID...");
  const [nsfw_allowed, setnsfw_allowed] = useState(false);
  const [tz, setTz] = useState("US/Eastern");
  const [msg_subscriptions,setmsg_subscriptions] = useState({
      awards: false,
      closeScores: false,
      loserOfTheWeek: false,
      matchups: false,
      postGameScoreboards: false,
      sundayScoreBoards: false,
      powerRankings: false,
      trashTalk: false
  });
  const [allSubs, setAllSubs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [groupMeAccessToken, setGroupMeAccessToken] = useState("");
  const [groupme_chat_name, setGroupme_chat_name] = useState("");
  const [chatList, setChatList] = useState([]);

  const [numWeeks, setNumWeeks] = useState(0);

  const [invalidLeagueID, setInvalidLeagueID] = useState(true);
  const [touchedLeagueID, setTouchedLeagueID] = useState(false);
  const [invalidEspnAuth, setInvalidEspnAuth] = useState(true);
  const [espnPubLeagueValidationCode, setEspnPubLeagueValidationCode] = useState("");


  const [botID, setBotID] = useState("");

  const [isPrivateLeague, setIsPrivateLeague] = useState(false);

  const [espnSWID,setEspnSWID] = useState("");
  const [espnS2,setEspnS2] = useState("");


  

  const renderLeagueIdTooltip = (props) => (
    <Tooltip id="league-id-tooltip" {...props}>
      <div className='league-id-tooltip-div'>
      Found in your browser URL<br></br>
      <b>NOT</b> in the ESPN FF mobile app
      {/*<Button className="league-id-button" size="sm" onClick={openAndPush} target="_blank">Click Here</Button>*/}
      </div>
    </Tooltip>
  );


  const renderTimezoneTooltip = (props) => (
    <Tooltip id="timezone-tooltip" {...props}>
      <div className='league-id-tooltip-div'>
      Certain message types (noted below) are scheduled to send based on this timezone setting.
      <br></br>
      <b>Note: Timezone cannot be updated after submitting this form. If you need to change this setting, please delete the league config and recreate it.</b>
      </div>
    </Tooltip>
  );

  useEffect(() => {
    function loadGroupMeChatNames() {
      const myInit = {
        queryStringParameters: {groupMeAccessToken}
      };

      return API.get("leagues", `/groupMe/chats`, myInit);
    }

    async function getGroupMeChatNames(){
      try {
        const chat_lst = await loadGroupMeChatNames();

        setChatList(chat_lst)
      } catch (e) {
        onError(e);
      }

    }

    window.addEventListener('storage', () => {

      const item = localStorage.getItem('groupMeAccessToken');
      if(item){
        setGroupMeAccessToken(item);
      }
      
      
    });

    //window.removeEventListener('storage')

    if(groupMeAccessToken && chatList.length == 0 ){
      getGroupMeChatNames();
    }

    else{
      console.log("No GM access token");

      }
       
  }, [groupMeAccessToken,chatList.length])

  
  function loadGroupMeBotID(bot) {      
    return API.post("leagues", "/groupMe/bots", {
      body: bot
    });
  }

  async function getGroupMeBotID(){

    setIsLoading(true);
  
    try {
      const resp = await loadGroupMeBotID({groupMeAccessToken, groupme_chat_name,msg_subscriptions});
      
      setIsLoading(false)

      return resp.bot_id
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function onChangeAwards(){
    setmsg_subscriptions(initialState => ({
        ...initialState,
        awards: !initialState.awards,
    }));
  }

  function onChangeCloseScores(){
    setmsg_subscriptions(initialState => ({
        ...initialState,
        closeScores: !initialState.closeScores,
    }));
  }
  
  function onChangeLoserOfTheWeek(){
    setmsg_subscriptions(initialState => ({
        ...initialState,
        loserOfTheWeek: !initialState.loserOfTheWeek,
    }));
  }

  function onChangeMatchups(){
    setmsg_subscriptions(initialState => ({
        ...initialState,
        matchups: !initialState.matchups,
    }));
  }

  function onChangeScoreboards(){
      setmsg_subscriptions(initialState => ({
        ...initialState,
        postGameScoreboards: !initialState.postGameScoreboards,
    }));

  }

  function onSundayScoreboards(){
    setmsg_subscriptions(initialState => ({
      ...initialState,
      sundayScoreBoards: !initialState.sundayScoreBoards,
  }));

}

  function onChangePowerRankings(){
    setmsg_subscriptions(initialState => ({
        ...initialState,
        powerRankings: !initialState.powerRankings,
    }));
  }

  function onChangeTrashTalk(){
    setmsg_subscriptions(initialState => ({
        ...initialState,
        trashTalk: !initialState.trashTalk,
    }));
  }
  
  function onChangeAllSubs(){
    let flag = !allSubs
    setAllSubs(flag)

    let setAll = (obj, val) => Object.keys(obj).forEach(k => obj[k] = val);
    let local_msg_subscriptions = msg_subscriptions
    setAll(local_msg_subscriptions, flag)
    if ('loserOfTheWeek' in local_msg_subscriptions){
      local_msg_subscriptions.loserOfTheWeek = false
    }
    setmsg_subscriptions(local_msg_subscriptions);
  }

  function validateForm() {
    var privateLeaugeValidation = true
    if (isPrivateLeague){
      privateLeaugeValidation = !invalidEspnAuth
    }

    return bot_platform.length > 0 && !invalidLeagueID && privateLeaugeValidation && groupme_chat_name;
  }


  async function handleSubmit(event) {
    event.preventDefault();
    
    const res = await getGroupMeBotID()
    const bot_id = await res
    
    setIsLoading(true)

    try {
      const r = await createLeague({ bot_platform, league_platform, league_id, league_name, bot_id, nsfw_allowed, tz, msg_subscriptions, numWeeks, groupMeAccessToken,espnSWID,espnS2});

      clearGroupMeTokenFromStorage()
      history.push("/");
    } catch (e) {
      if (e.response.data.statusCode && (e.response.data.statusCode === "444" || e.response.data.statusCode === "456" || e.response.data.statusCode === "466" || e.response.data.statusCode === "499")){
        alert(e.response.data.errorMsg)
        setIsLoading(false);
        //throw new Error(e.response.data.errorMsg);
      }  
    }

  }
  
  function createLeague( league ) {
    return API.post("leagues", "/leagues", {
      body: league
    });
  }

  function clearGroupMeTokenFromStorage() {
    localStorage.removeItem('groupMeAccessToken');
  };


  function validatePrivateLeague(privateLeague){
    return API.post("leagues", "/leagues/espn-validation", {
      body: privateLeague
    });
  }

  useEffect(() => {
    async function validateEspnData(){
     // {console.log("ESPN Validation triggered")}
      setleague_name("")
      const espn_league_id = league_id;
      const espn_api_url = "https://fantasy.espn.com/apis/v3/games/ffl/seasons/" + new Date().getFullYear() + "/segments/0/leagues/" + espn_league_id + "?view=mSettings";
    
      if (isPrivateLeague ){
        //{console.log("ESPN Validation triggered -> isPrivateLeague")}
        if (touchedLeagueID && league_id && espnS2 && espnSWID){
         // {console.log("ESPN Validation triggered -> isPrivateLeauge -> required values exist so validating")}
          try {
            setIsLoading(true);
            const validationRes = await validatePrivateLeague({espn_api_url, espnS2, espnSWID})
            //{console.log(validationRes)}
            //{console.log(validationRes.settings.name)}
            setleague_name(validationRes.settings.name + " (ID: " + espn_league_id + ")");
            setNumWeeks(validationRes.status.finalScoringPeriod + 1)
            setInvalidLeagueID(false)
            setInvalidEspnAuth(false)
      
          } catch (e) {
            if (e.response.data.statusCode && (e.response.data.statusCode === "444" || e.response.data.statusCode === "456")){
              //{console.log("ESPN Validation triggered -> isPrivateLeauge -> in IF -> in Known Errors")}
              setInvalidEspnAuth(true)
              setInvalidLeagueID(true)
            }
            else{
              //{console.log("ESPN Validation triggered -> isPrivateLeauge -> in IF -> in unknown errors")}
              setInvalidEspnAuth(true)
              setInvalidLeagueID(true)
              onError(e)
            } 
          }
          setIsLoading(false);
        }
        else{
          //{console.log("ESPN Validation triggered -> isPrivateLeauge -> required values dont exist, not validating")}
          setInvalidEspnAuth(true)
          setInvalidLeagueID(true)
        }
      }
     
      else {
        //{console.log("ESPN Validation triggered -> in public league")}
        if (touchedLeagueID){
          //console.log("ESPN Validation triggered -> in public league -> touched so validate")
          fetch( espn_api_url)
            .then(response => {
              if (!response.ok) {
                setEspnPubLeagueValidationCode(""+response.status);
                //{console.log(""+response.status)}
                throw new Error('Invalid League ID. Please verify and try again.');
              }
              return response.json();
    
            })
            .then(validationRes => {
              //{console.log(validationRes.settings.name)}
              setleague_name(validationRes.settings.name + " (ID: " + espn_league_id + ")");
              setNumWeeks(validationRes.status.finalScoringPeriod + 1)
              setInvalidLeagueID(false)
            }).catch(error =>{
              setInvalidLeagueID(true)
              //console.error('Invalid League ID. Please verify and try again.');
              //onError('Invalid League ID. Please verify and try again.');
            });
        }
        //else {console.log("ESPN Validation triggered -> in public league -> not touched so do nothing")}
      }
    }

    validateEspnData();
  }, [league_id,touchedLeagueID,espnSWID,espnS2,isPrivateLeague]);


  function onBlurLeagueID(leagueID) {
    setTouchedLeagueID(true)
    setleague_id(leagueID)
  }

  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const toggleOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <div className = {classNames('cookieDefaultToggleStyle',{'cookieToggleStyle' : isCurrentEventKey} )} onClick={toggleOnClick}>
        {children}
      </div>
    );
  }

  function onBlurEspnSWID(swid) {
    var regex_swid = /[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}/;
    var result = regex_swid.exec(swid)

    if (result){
      setEspnSWID(result[0])
      console.log("valid espn swid regex: "+ result[0])
    }

    else{
      console.log("invalid espn swid regex: " + swid)
      setEspnSWID(swid)
    }
  }

  function onBlurEspnS2(espnS2) {
    var espnS2_lst = espnS2.split(/\b\s+/)

    var s2_candidates = []
    for (const i in espnS2_lst){
      const el = espnS2_lst[i]
      if (el.length >= 150){
        s2_candidates.push(el)
      }
    }

    if (s2_candidates.length > 0){
      console.log("valid espn  s2: "+ s2_candidates[0])
      setEspnS2(s2_candidates[0])
      
    }

    else{
      console.log("invalid espn  s2: "+ espnS2)
      setEspnS2(espnS2)
    }
  }

  return (
    <div className="NewLeague">
      <form onSubmit={handleSubmit}>
        { !groupMeAccessToken &&
          <div className="GroupMeAuth">
          <h4>Log in to GroupMe to Continue</h4>
          <Button className="groupme-button" href="https://oauth.groupme.com/oauth/authorize?client_id=aQuV9S14bZngzLFlzKUoJkxgjTrjEyxAlRydcIqolFjo2zs7" target="blank_">{/*onClick={clearGroupMeTokenFromStorage}>*/}
            GroupMe Login
          </Button>
          </div>
        }
                
        { groupMeAccessToken &&
          <div>
          
          <h2 className="add-league-header">Add a New League</h2>
          <br></br>
          <FormGroup controlId="league_visibility">
              <FormLabel>League Visibility</FormLabel>
              <br></br>
                <FormCheck 
                  inline
                  checked={!isPrivateLeague}
                  onChange={e => setIsPrivateLeague(!e.target.value)}
                  label="Public"
                  id="leagueVis-public"
                  type="radio"
                ></FormCheck>
                <FormCheck 
                  inline
                  checked={isPrivateLeague}
                  onChange={e => setIsPrivateLeague(e.target.value)}
                  label="Private"
                  id="leagueVis-private"
                  type="radio"
                ></FormCheck>
                <div className="vis-help">(Found in league settings on ESPN fantasy site/app)</div>
          </FormGroup>
          {isPrivateLeague &&
            <div> 
            <FormGroup controlId="espn_swid">
            <FormLabel>ESPN SWID Cookie</FormLabel>
                  <FormControl
                    as="input"
                    type="text"
                    placeholder={espnSWID}
                    //onChange={e => setEspnSWID(e.target.value)}
                    //onBlur={validateEspnData}
                    onBlur={e => onBlurEspnSWID(e.target.value)}
                    //required
                    isInvalid = {touchedLeagueID ? invalidEspnAuth : false}
                    isValid = {touchedLeagueID ? !invalidEspnAuth : false}
                  >
                  </FormControl>
            <FormControl.Feedback type="invalid">
            {isLoading ? 
                  (<p>Validation in progress. Please wait...</p>)
                  :
                  (<p>Please make sure the values for this field, ESPN S2, and League ID are all correct.</p>)
            }
            </FormControl.Feedback>
            <FormControl.Feedback type="valid">Valid ESPN SWID</FormControl.Feedback>
          </FormGroup>
          <Accordion defaultActiveKey="1">
                <Card className="cookies-help">
                  <Card.Header>
                  <ContextAwareToggle eventKey="1">
                    How to Find ESPN SWID Value
                  </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                    <ol>
                      <li>Using a <b>desktop</b> computer, log in to your ESPN account at <a href="https://www.espn.com/fantasy/" target="blank_">https://www.espn.com/fantasy</a></li>
                      <li>Right click anywhere on the ESPN website</li>
                      <li><b>Firefox / Safari:</b>  Click "Inspect Element" (Note: if you do not see this option in Safari, follow <a href="https://www.browserstack.com/guide/how-to-inspect-element-on-mac" target="blank_">these instructions</a>)
                      <br></br><b>Chrome:</b> Click "Inspect"</li>
                      <li><b>Firefox / Safari:</b> Click the "Storage" tab -> Cookies -> www.espn.com <br></br><b>Chrome:</b> Click the "Application" tab -> Cookies -> www.espn.com</li>
                      <li>Find the cookie with the name "SWID" and copy/paste the value to the LeagueBot form. <br></br><b>Note: There may be more than one cookie named SWID. Make sure you use the one that is wrapped in curly braces. </b></li>
                    </ol>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>SWID Example:</b> {"{FF1868B5-9738-834E-01FI-8462E8CA6528}"}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
          </Accordion>
          <FormGroup controlId="espn_s2">
            <FormLabel>ESPN S2 Cookie</FormLabel>
                  <FormControl
                    as="input"
                    type="text"
                    placeholder={espnS2}
                    //value={league_id}
                    //onChange={e => setEspnS2(e.target.value)}
                    //onBlur={validateEspnData}
                    onBlur={e => onBlurEspnS2(e.target.value)}
                    //required
                    isInvalid = {touchedLeagueID ? invalidEspnAuth : false}
                    isValid = {touchedLeagueID ? !invalidEspnAuth : false}
                  >
                  </FormControl>
            <FormControl.Feedback type="invalid">
            {isLoading ? 
                  (<p>Validation in progress. Please wait...</p>)
                  :
                  (<p>Please make sure the values for this field, ESPN SWID, and League ID are all correct.</p>)
            }
            </FormControl.Feedback>
            <FormControl.Feedback type="valid">Valid ESPN S2 </FormControl.Feedback>
          </FormGroup>
          <Accordion>
                <Card className="cookies-help">
                  <Card.Header>
                  <ContextAwareToggle eventKey="1">
                    How to Find ESPN S2 Value
                  </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                    <ol>
                      <li>Using a <b>desktop</b> computer, log in to your ESPN account at <a href="https://www.espn.com/fantasy/" target="blank_">https://www.espn.com/fantasy</a></li>
                      <li>Right click anywhere on the ESPN website</li>
                      <li><b>Firefox / Safari:</b>  Click "Inspect Element" (Note: if you do not see this option in Safari, follow <a href="https://www.browserstack.com/guide/how-to-inspect-element-on-mac" target="blank_">these instructions</a>)
                      <br></br><b>Chrome:</b> Click "Inspect"</li>
                      <li><b>Firefox / Safari:</b> Click the "Storage" tab -> Cookies -> www.espn.com <br></br><b>Chrome:</b> Click the "Application" tab -> Cookies -> www.espn.com</li>
                      <li>Find the cookie with the name "espn_s2" and copy/paste the value to the LeagueBot form. </li>
                    </ol>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>ESPN S2 Example:</b> 4yjiwb103KMF4XzSrxGu332PB%StxzPT1Dj266bYjQYZENUemBQKaLC7Me3pAMNvNyOnPgIA24ZZHshnpOy4dAhX7EweNBnE18APvg6Ol6MZT1sXmjTIvAkXNgKxdV7AuxBx9iNmMuJChC4yvswYg8pMj4E4njoU7YhDiRgiT4gX83Vufn25935lQIoeLZKWfoxzhPR6lCNs6ADcrtwXgQddCyG0y31sE4Ih7e7Ps5p1IHPx18cfnOCM%xA%kzRhvpXBWk3NxLAAwM7f3HKHq5j1BiJ1sof4q0Wb%AJ8UKcJvKmhLJqrBigVIA2m%JWl
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
          </Accordion>
          </div>
          }
          
          <FormGroup controlId="league_id">
            <div><FormLabel>League ID</FormLabel></div>
            <Image className="league-id-pic" src={leagueIdPic}></Image>
            <OverlayTrigger
                placement="auto"
                overlay={renderLeagueIdTooltip}
                trigger={["focus","hover"]}>
                  <FormControl
                    as="input"
                    type="text"
                    placeholder={league_id}
                    //value={league_id}
                    //onChange={e => setleague_id(e.target.value)}
                    onBlur={e => onBlurLeagueID(e.target.value)}
                    //required
                    isInvalid = {touchedLeagueID ? invalidLeagueID : false}
                    isValid = {touchedLeagueID ? !invalidLeagueID : false}
                  >
                  </FormControl>
            </OverlayTrigger>
            <FormControl.Feedback type="invalid">
            {!isPrivateLeague ? (
                espnPubLeagueValidationCode === "401" ?
                  (<p>League ID is associated with a private league. Please update the league visibility option above or change this league to public on the ESPN fantasy site/app.</p>)
                  :
                  (<p>Invalid ESPN League ID. Please make sure the value is correct.</p>)
              )
              :
              (isLoading ? 
                  (<p>Validation in progress. Please wait...</p>)
                  :
                  (<p>Please make sure the values for this field, ESPN S2, and ESPN SWID are all correct.</p>)
              )
            }
            </FormControl.Feedback>
            <FormControl.Feedback type="valid">Valid League ID. Please verify that the league name below matches your league name on the ESPN Fantasy website/app.</FormControl.Feedback>
          </FormGroup>
          <div>{ league_name &&
            (<FormGroup controlId="tz">
              <FormLabel>League Name</FormLabel>
              <FormControl
                  as="input"
                  type="text"
                  //placeholder={tz}
                  value={league_name.split(" (ID: ")[0]}
                  disabled
              />
            </FormGroup>)
          }
          </div>
          <FormGroup controlId="groupme_chat_name">
            <FormLabel>GroupMe Chat Name</FormLabel>
            <FormControl
              as="select"
              onChange={e => setGroupme_chat_name(e.target.value)}
              required
            >
                <option key="default" value="">Select chat name...</option>
                {chatList ?
                  (chatList.map(chat => {
                    return <option key={chat.group_id} value={chat.group_id}>{chat.name}</option>
                })) : ""}
            </FormControl>
          </FormGroup>
          <FormGroup controlId="tz">
              <FormLabel>Timezone</FormLabel>
              <br></br>
              <OverlayTrigger
                placement="auto"
                overlay={renderTimezoneTooltip}
                trigger={["focus","hover"]}
              >
              <TimezonePicker
                  absolute      = {false}
                  placeholder   = {tz}
                  onChange      = {setTz}
                  className     = "tzPicker"
                  defaultValue={'US/Eastern'}
                  timezones={timezoneList}
                  />
              </OverlayTrigger>
          </FormGroup>
          <FormGroup controlId="msg_subscriptions">
              <FormLabel>Message Subscriptions</FormLabel>
              <br></br>
              <Accordion>
              <Card>
                  <Card.Header className="allSubs-header" eventKey="6">
                    <FormCheck 
                    //inline
                    checked={allSubs}
                    onChange={onChangeAllSubs}
                    label="All"
                    id="All Toggle"
                    type="switch"
                    ></FormCheck>
                  </Card.Header>
                </Card>
                <Card>
                <Card.Header>
                  <ContextAwareToggle eventKey="7">
                    <FormCheck 
                    //inline
                    checked={msg_subscriptions.trashTalk}
                    onChange={onChangeTrashTalk}
                    label="Trash Talk"
                    id="Trash Talk"
                    type="switch"
                    ></FormCheck>
                  </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="7">
                    <Card.Body>
                      <b>Description: </b> <br></br>
                      LeagueBot uses in-season data to direct unbiased trash talk at league members who may not be as good at fantasy as they think they are.
                      <br></br><br></br>
                      Instead of being sent on a regular schedule, Trash Talk messages are added to other types of messages seen below (usually <i>Weekly Awards</i> and <i>Power Rankings</i>).
                      <br></br><br></br>
                      <b>Example:</b><br></br>
                      *knock knock*
                      <br></br><br></br>
                      Who's there?<br></br>
                      21 days
                      <br></br><br></br>
                      21 days who?<br></br>
                      21 days since John Smith's last win
                      <br></br>

                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                  <ContextAwareToggle as={Card.Header} eventKey="1">
                    <FormCheck 
                    //inline
                    checked={msg_subscriptions.closeScores}
                    onChange={onChangeCloseScores}
                    label="Close Scores – "
                    id="Close Scores"
                    type="switch"
                    ></FormCheck>
                    <i>&nbsp;Monday @ 7:00 p.m. US/Eastern</i>
                  </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <b>Description: </b> <br></br>
                      Keep an eye on matchups that are within 15 points going into Monday Night Football
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                  <ContextAwareToggle eventKey="0">
                    <FormCheck 
                        //inline
                        checked={msg_subscriptions.awards}
                        onChange={onChangeAwards}
                        label="Weekly Awards – "
                        id="Weekly Awards"
                        type="switch"
                    ></FormCheck>
                    <i>&nbsp;Tuesday @ 7:30 p.m. local time</i>
                  </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <b>Description: </b> <br></br>
                      🏆&nbsp;&nbsp;Most Points<br></br>
                      💩&nbsp;&nbsp;Least Points<br></br>
                      🤯&nbsp;&nbsp;Biggest Blowout<br></br>
                      😅&nbsp;&nbsp;Closest Game<br></br>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                <Card.Header>
                  <ContextAwareToggle eventKey="5">
                    <FormCheck 
                    //inline
                    checked={msg_subscriptions.powerRankings}
                    onChange={onChangePowerRankings}
                    label="Power Rankings – "
                    id="Power Rankings"
                    type="switch"
                    ></FormCheck>
                    <i>&nbsp;Tuesday @ 7:00 p.m. local time</i>
                  </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>
                      <b>Description: </b> <br></br>
                      Weekly rankings based on quality of wins, points scored, and margins of victory 
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                  <ContextAwareToggle eventKey="2">
                    <FormCheck 
                    //inline
                    checked={msg_subscriptions.matchups}
                    onChange={onChangeMatchups}
                    label="Matchups – "
                    id="Matchups"
                    type="switch"
                    ></FormCheck>
                    <i>&nbsp;Thursday @ 7:00 p.m. US/Eastern</i>
                  </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <b>Description: </b> <br></br>
                      Reminder of who's playing who, sent before Thursday Night Football kicks off
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                <Card.Header>
                  <ContextAwareToggle eventKey="3">
                    <FormCheck 
                    //inline
                    checked={msg_subscriptions.postGameScoreboards}
                    onChange={onChangeScoreboards}
                    label="Scoreboard Updates – "
                    id="Scoreboard Updates"
                    type="switch"
                    ></FormCheck>
                    <i>&nbsp;Monday, Tuesday, and Friday @ 6:30 p.m. local time</i>
                  </ContextAwareToggle>
                </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <b>Description: </b> <br></br>
                      Stay up-to-date on how each matchup is going with fantasy scoreboard updates after each gameday
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                <Card.Header>
                  <ContextAwareToggle eventKey="4">
                    <FormCheck 
                    //inline
                    checked={msg_subscriptions.sundayScoreBoards}
                    onChange={onSundayScoreboards}
                    label="Sunday Scoreboard Updates – "
                    id="Sunday Scoreboard Updates"
                    type="switch"
                    ></FormCheck>
                    <i>&nbsp;Sunday @ 4:00 p.m. and 8:00 p.m. US/Eastern</i>
                  </ContextAwareToggle>
                </Card.Header>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <b>Description: </b> <br></br>
                      Stay up-to-date on how each matchup is going with fantasy scoreboard updates after each Sunday afternoon time slot
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
          </FormGroup>
          
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          &nbsp;&nbsp;Create
        </LoaderButton>
        </div>
      }
      </form>
    </div>
  );
}

/*
javascript: document.write(
    `<pre>${JSON.stringify(
      document.cookie.split(";").reduce((cookies, val) => {
        parts = val.split("=");
        if(parts[0] === " espn_s2" || parts[0] === " SWID"){
        cookies[parts[0]] = parts[1];
        }
        else {
            delete cookies[parts[0]];
        }
        return cookies;
      }, {}),
      null,
      2
    )}</pre>`
  );

  */