
import React, { useRef, useState, useEffect } from "react";
import queryString from 'query-string'


export default function GroupMeRedirect() {
    const [groupMeToken, setGroupMeToken] = useState("")

    useEffect(() => {
        async function onLoad() {
            const values = queryString.parse(window.location.search)
            setGroupMeToken(values.access_token)
        }
    
        onLoad();
      }, []);

    function checkToken(){
        if (groupMeToken){
            localStorage.setItem('groupMeAccessToken', groupMeToken);
            window.close();
            return "Successfully logged in to groupMe. You may close this window."
        }

        else{
            return "GroupMe login failed. Close this window and try again."
        }
    }

    return (
        <h1>
            {checkToken()}
        </h1>
    );
}