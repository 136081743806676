import React, { useState } from "react";
import { Auth, API} from "aws-amplify";
import { Form, FormGroup, FormControl, FormLabel} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Login.css";

import { useHistory, Link } from "react-router-dom";

export default function Login() {
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmationCode: "",
  });

  const [verificationCodeResent, setVerificationCodeResent] = useState(false);
  const history = useHistory();

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);

      const info = await loadUserInfo();  // use info to await

      userHasAuthenticated(true);
      
      // call this from inside addUser lambda after if exists check to save time
      const timeOfCreationResponse = await loadTimeOfCreation({userPoolId: info.pool.userPoolId, username: info.username})

      const addUserResponse = await addUser({cognitoEmail: info.attributes.email,
                                             cognitoSub: info.attributes.sub,
                                             timeOfCreation: timeOfCreationResponse.timeOfCreation
                                              });

      


    } catch (e) {
      if (e.message === 'User is not confirmed.') {
        alert("You are already registered but need to verify your email. Resending the verification code to " + fields.email);
        setIsLoading(true);
        try {
          await Auth.resendSignUp(fields.email);

          setIsLoading(false);
          setVerificationCodeResent(true);
          } catch (e) {
            onError(e);
          }
      }
      else{onError(e);}

      setIsLoading(false);
    }
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);
  
      userHasAuthenticated(true);

      const info = await loadUserInfo();
      
      // call this from inside addUser lambda after if exists check to save time
      const timeOfCreationResponse = await loadTimeOfCreation({userPoolId: info.pool.userPoolId, username: info.username})


      var userObject = {cognitoEmail: info.attributes.email,
        cognitoSub: info.attributes.sub,
        timeOfCreation: timeOfCreationResponse.timeOfCreation,
        marketingSource: info.attributes['custom:marketingSource']
        }

      if( info.attributes['custom:affiliateID']){
        userObject.affiliateID = info.attributes['custom:affiliateID']
      }

      const addUserResponse = await addUser(userObject);
                                              
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function addUser(user) {
    return API.post("leagues", "/users", {body: user});
  }
  
  function loadUserInfo(){
    return Auth.currentAuthenticatedUser()
  }

  function loadTimeOfCreation(params){
    return API.post("leagues", "/users/timeOfCreation", {body: params});
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <FormLabel>Confirmation Code</FormLabel>
          <FormControl
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <Form.Text>Please check your email for the code.</Form.Text>
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          &nbsp;&nbsp;Verify
        </LoaderButton>
      </form>
    );
  }

  function renderLoginForm() {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <FormLabel>Password</FormLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <Link to="/login/reset">Forgot password?</Link>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
        &nbsp;&nbsp;Login
        </LoaderButton>
      </form>
    );
  }

  return (
    <div className="Login">
      {verificationCodeResent === false ? renderLoginForm() : renderConfirmationForm()}
    </div>
  );
}