import React, { useRef, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { onError } from "../libs/errorLib";
import { FormGroup, FormLabel, FormCheck, Accordion, Card, FormControl} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./Leagues.css";
import pageLoadSpinner from "../components/PageLoadSpinner";



export default function Leagues() {
  const { id } = useParams();
  const history = useHistory();
  const [league, setLeague] = useState(null);
  const [tz, setTz] = useState("");
  const [msg_subscriptions, setmsg_subscriptions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [leagueName, setLeagueName] = useState("");
  const [allSubs, setAllSubs] = useState(false);
  

  useEffect(() => {
    function loadLeague() {
      return API.get("leagues", `/leagues/${id}`);
    }

    async function onLoad() {
      try {
        setIsLoading(true)
        const league = await loadLeague();

        setLeague(league);
        setTz(league.tz);
        setLeagueName(league.leagueName);
        setmsg_subscriptions(league.msgSubscriptions);
      } catch (e) {
        onError(e);
      }
      setIsLoading(false)
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return true;
  }

  function saveLeague(league) {
    return API.put("leagues", `/leagues/${id}`, {
      body: league
    });
  }
  
  function onChangeAwards(){
    setmsg_subscriptions(initialState => ({
        ...initialState,
        awards: !initialState.awards,
    }));
  }

  function onChangeCloseScores(){
    setmsg_subscriptions(initialState => ({
        ...initialState,
        closeScores: !initialState.closeScores,
    }));
  }
  
  function onChangeLoserOfTheWeek(){
    setmsg_subscriptions(initialState => ({
        ...initialState,
        loserOfTheWeek: !initialState.loserOfTheWeek,
    }));
  }

  function onChangeMatchups(){
    setmsg_subscriptions(initialState => ({
        ...initialState,
        matchups: !initialState.matchups,
    }));
  }

  function onChangeScoreboards(){
      setmsg_subscriptions(initialState => ({
        ...initialState,
        postGameScoreboards: !initialState.postGameScoreboards,
    }));

  }

  function onSundayScoreboards(){
    setmsg_subscriptions(initialState => ({
      ...initialState,
      sundayScoreBoards: !initialState.sundayScoreBoards,
  }));

}

  function onChangePowerRankings(){
    setmsg_subscriptions(initialState => ({
        ...initialState,
        powerRankings: !initialState.powerRankings,
    }));
  }

  function onChangeTrashTalk(){
    setmsg_subscriptions(initialState => ({
        ...initialState,
        trashTalk: !initialState.trashTalk,
    }));
  }
  
  function onChangeAllSubs(){
    let flag = !allSubs
    setAllSubs(flag)

    let setAll = (obj, val) => Object.keys(obj).forEach(k => obj[k] = val);
    let local_msg_subscriptions = msg_subscriptions
    setAll(local_msg_subscriptions, flag)

    if ('loserOfTheWeek' in local_msg_subscriptions){
      local_msg_subscriptions.loserOfTheWeek = false
    }

    setmsg_subscriptions(local_msg_subscriptions);
  }

  async function handleSubmit(event) {  
    event.preventDefault();
    setIsLoading(true);
  
    try {
      await saveLeague({
        msg_subscriptions
      });
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function deleteLeague() {
    return API.del("leagues", `/leagues/${id}`);
  }

  async function handleDelete(event) {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to delete this league?"
    );
  
    if (!confirmed) {
      return;
    }
  
    setIsDeleting(true);

    try {
      await deleteLeague();
      history.push("/");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }

  function renderHeader(){
    return (
      <h2 className="league-header">{leagueName}</h2>
    );
  }

  //onst header = (<h2>{leagueName}</h2>)

  return (
    <div className="Leagues">
    {isLoading && !league ? pageLoadSpinner(isLoading) :
    (
      <form onSubmit={handleSubmit}>
      {leagueName && (renderHeader())}
      <br></br>
      <FormGroup controlId="tz">
            <div><FormLabel>Timezone</FormLabel></div>
      <FormControl
          as="input"
          type="text"
          //placeholder={tz}
          value={tz}
          disabled
        ></FormControl>
        </FormGroup>
      <FormGroup controlId="msg_subscriptions">
              <FormLabel>Message Subscriptions</FormLabel>
              <br></br>
              <Accordion>
              <Card>
                  <Card.Header className="allSubs-header" eventKey="6">
                    <FormCheck 
                    //inline
                    checked={allSubs}
                    onChange={onChangeAllSubs}
                    label="All"
                    id="All Toggle"
                    type="switch"
                    ></FormCheck>
                  </Card.Header>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header}  eventKey="7">
                    <FormCheck 
                    //inline
                    checked={msg_subscriptions.trashTalk || false}
                    onChange={onChangeTrashTalk}
                    label="Trash Talk"
                    id="Trash Talk"
                    type="switch"
                    ></FormCheck>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="7">
                    <Card.Body>
                      <b>Description: </b> <br></br>
                      LeagueBot uses in-season data to direct unbiased trash talk at league members who may not be as good at fantasy as they think they are.
                      <br></br><br></br>
                      Instead of being sent on a regular schedule, Trash Talk messages are added to other types of messages seen below (usually <i>Weekly Awards</i> and <i>Power Rankings</i>).
                      <br></br><br></br>
                      <b>Example:</b><br></br>
                      *knock knock*
                      <br></br><br></br>
                      Who's there?<br></br>
                      21 days
                      <br></br><br></br>
                      21 days who?<br></br>
                      21 days since John Smith's last win
                      <br></br>

                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header}  eventKey="1">
                    <FormCheck 
                    //inline
                    checked={msg_subscriptions.closeScores}
                    onChange={onChangeCloseScores}
                    label="Close Scores – "
                    id="Close Scores"
                    type="switch"
                    ></FormCheck>
                    <i>&nbsp;Monday @ 7:00 p.m. US/Eastern</i>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <b>Description: </b> <br></br>
                      Keep an eye on matchups that are within 15 points going into Monday Night Football
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    <FormCheck 
                        //inline
                        checked={msg_subscriptions.awards}
                        onChange={onChangeAwards}
                        label="Weekly Awards – "
                        id="Weekly Awards"
                        type="switch"
                    ></FormCheck>
                    <i>&nbsp;Tuesday @ 7:30 p.m. local time</i>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <b>Description: </b> <br></br>
                      🏆&nbsp;&nbsp;Most Points<br></br>
                      💩&nbsp;&nbsp;Least Points<br></br>
                      🤯&nbsp;&nbsp;Biggest Blowout<br></br>
                      😅&nbsp;&nbsp;Closest Game<br></br>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="5">
                    <FormCheck 
                    //inline
                    checked={msg_subscriptions.powerRankings}
                    onChange={onChangePowerRankings}
                    label="Power Rankings – "
                    id="Power Rankings"
                    type="switch"
                    ></FormCheck>
                    <i>&nbsp;Tuesday @ 7:00 p.m. local time</i>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>
                      <b>Description: </b> <br></br>
                      Weekly rankings based on quality of wins, points scored, and margins of victory 
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="2">
                    <FormCheck 
                    //inline
                    checked={msg_subscriptions.matchups}
                    onChange={onChangeMatchups}
                    label="Matchups – "
                    id="Matchups"
                    type="switch"
                    ></FormCheck>
                    <i>&nbsp;Thursday @ 7:00 p.m. US/Eastern</i>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <b>Description: </b> <br></br>
                      Reminder of who's playing who, sent before Thursday Night Football kicks off
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="3">
                    <FormCheck 
                    //inline
                    checked={msg_subscriptions.postGameScoreboards}
                    onChange={onChangeScoreboards}
                    label="Scoreboard Updates – "
                    id="Scoreboard Updates"
                    type="switch"
                    ></FormCheck>
                    <i>&nbsp;Monday, Tuesday, and Friday @ 6:30 p.m. local time</i>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <b>Description: </b> <br></br>
                      Stay up-to-date on how each matchup is going with fantasy scoreboard updates after each gameday
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="4">
                    <FormCheck 
                    //inline
                    checked={msg_subscriptions.sundayScoreBoards}
                    onChange={onSundayScoreboards}
                    label="Sunday Scoreboard Updates – "
                    id="Sunday Scoreboard Updates"
                    type="switch"
                    ></FormCheck>
                    <i>&nbsp;Sunday @ 4:00 p.m. and 8:00 p.m. US/Eastern</i>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <b>Description: </b> <br></br>
                      Stay up-to-date on how each matchup is going with fantasy scoreboard updates after each Sunday afternoon time slot
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </FormGroup>
        <LoaderButton
          block
          type="submit"
          size="large"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          &nbsp;&nbsp;Save
        </LoaderButton>
        <LoaderButton
          block
          size="large"
          variant="danger"
          onClick={handleDelete}
          isLoading={isDeleting}
        >
          &nbsp;&nbsp;Delete
        </LoaderButton>
      </form>
    )
    }
  </div>
    

  );
}
