import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  FormGroup,
  FormControl,
  FormLabel,
  Form,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Signup.css";
import { Auth, API} from "aws-amplify";
import queryString from 'query-string'


export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [affiliateID, setAffiliateID] = useState("");
  const [marketingSource, setMarketingSource] = useState("N/A")

  const sourceList = [
    "Search Engine",
    "Reddit post",
    "Instagram post/story",
    "Twitch",
    "Other social media",
    "Word of mouth",
    "Other"
  ]

  useEffect(() => {
      async function onLoad() {
          const urlParams = queryString.parse(window.location.search)
          setAffiliateID(urlParams.referral)
      }
  
      onLoad();
    }, []);

  const renderPasswordTooltip = (props) => (
    <Tooltip id="password-tooltip" {...props}>
      <div className='text-justify'>
        <b>Must Contain:</b><br></br>
        At least 8 characters<br></br>
        1 uppercase letter<br></br>
        1 lowercase letter<br></br>
        1 special character<br></br>
      </div>
    </Tooltip>
  );
  

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length >= 8 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);

    var userArgs = {
      username: fields.email,
      password: fields.password,
      attributes: {'custom:marketingSource' : marketingSource}
    }

    if ( affiliateID ) {
      userArgs.attributes['custom:affiliateID'] = affiliateID
    }
  
    try {
      const newUser = await Auth.signUp(userArgs);
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      
      if(e.name === 'UsernameExistsException') {
        alert("You are already registered. Resending the verification code to " + fields.email);
        setIsLoading(true);
        try {
          const newUser = await Auth.resendSignUp(fields.email);

          setIsLoading(false);
          setNewUser(newUser);
          } catch (e) {
            onError(e);
          }
      }
      else{onError(e);}

      setIsLoading(false);
    }
  }

  

  async function handleConfirmationSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);
  
      userHasAuthenticated(true);

      const info = await loadUserInfo();
      //setUserInfo(info);
      
      // call this from inside addUser lambda after if exists check to save time
      const timeOfCreationResponse = await loadTimeOfCreation({userPoolId: info.pool.userPoolId, username: info.username})

      var userObject = {cognitoEmail: info.attributes.email,
        cognitoSub: info.attributes.sub,
        timeOfCreation: timeOfCreationResponse.timeOfCreation,
        marketingSource: info.attributes['custom:marketingSource']
        }

      if( info.attributes['custom:affiliateID']){
        userObject.affiliateID = info.attributes['custom:affiliateID']
      }

      const addUserResponse = await addUser(userObject);

      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  function addUser(user) {
    return API.post("leagues", "/users", {body: user});
  }
  
  function loadUserInfo(){
    return Auth.currentAuthenticatedUser()
  }

  function loadTimeOfCreation(params){
    return API.post("leagues", "/users/timeOfCreation", {body: params});
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <FormLabel>Confirmation Code</FormLabel>
          <FormControl
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <Form.Text>Please check your email for the code.</Form.Text>
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          &nbsp;&nbsp;Verify
        </LoaderButton>
      </form>
    );
  }

  function renderForm() {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <OverlayTrigger
          placement="auto"
          overlay={renderPasswordTooltip}
          trigger="focus"

        >
          <FormGroup controlId="password" bsSize="large">
            <FormLabel>Password</FormLabel>
            <FormControl
              type="password"
              value={fields.password}
              onChange={handleFieldChange}
            />
          </FormGroup>
        </OverlayTrigger>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <FormLabel>Confirm Password</FormLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </FormGroup>
        <FormGroup controlId="marketing_source">
            <FormLabel>How did you hear about LeagueBot?</FormLabel>
            <FormControl
              as="select"
              onChange={e => setMarketingSource(e.target.value)}
            >
                <option key="default" value="N/A">Select...</option>
                {sourceList.map(src => {
                    return <option key={src} value={src}>{src}</option>
                })}
            </FormControl>
          </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          &nbsp;&nbsp;Signup
        </LoaderButton>
      </form>
    );
  }

  return (
    <div className="Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
  );
}
